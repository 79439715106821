import React, { Component } from 'react'
// import { Row, Col } from 'reactstrap'
import Lottie from 'react-lottie';
import { Parallax } from 'react-scroll-parallax';
import FormStepperComponent from '../../../components/FormStepper'
import Button from '@material-ui/core/Button';

// images 
import * as animationData from '../../../images/lottie/workflow_colored.json'
import * as mascot from '../../../images/lottie/hero_mascot.json'
import shape from '../../../images/application/shape/hero.svg'
// import shape2 from '../../../images/application/shape/shape5.svg'
// import hero from '../../../images/application/hero.svg'


class ApplicationHero extends Component {
    state = {
        setTrue: false,
    }

    // const ApplicationHero = () => {
    // const defaultOptions = {
    //     loop: false,
    //     autoplay: true,
    //     renderer: 'canvas',
    //     animationData: animationData.default,
    //     rendererSettings: {
    //         preserveAspectRatio: 'xMidYMid slice'
    //     }
    // };
    // const mascotOption = {
    //     loop: true,
    //     autoplay: true,
    //     renderer: 'canvas',
    //     animationData: mascot.default,
    //     rendererSettings: {
    //         preserveAspectRatio: 'xMidYMid slice'
    //     }
    // };

    openDialog = () => {
        this.setState({ setTrue: true });
    }
    redirectToWorkFlow = () => {
        window.location.href = `https://workflow.payserv.net/`;
    }

    render() {
        let screenWidth = window.innerWidth;
        let screenHeight = window.innerHeight;
        console.log('WINDOW WIDTH', window.innerWidth);
        console.log('WINDOW HEIGHT', window.innerHeight);

        let { openDialog } = this.props;
        openDialog = false;
        if (this.state.setTrue) {
            openDialog = true;
        }
        const defaultOptions = {
            loop: false,
            autoplay: true,
            renderer: 'canvas',
            animationData: animationData.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        const mascotOption = {
            loop: true,
            autoplay: true,
            renderer: 'canvas',
            animationData: mascot.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div className="applicationHeroArea" id="home" >
                <div className="container">
                    <div className="rowCont">
                        <div className="col-lg-6 col-12">
                            <Parallax className="heroImage text-right" x={[-20, 20]} tagOuter="div">
                                <Lottie
                                    width={screenWidth <= 320 ? 290 : screenWidth <= 360 ? 300 : 500}
                                    height={screenHeight <= 650 ? 200 : screenHeight <= 660 ? 200 : 400 }
                                    // height={400}
                                    // width={500}
                                    options={mascotOption} />
                            </Parallax>
                        </div>
                        <div className="col-lg-6 col-12">
                            <Parallax className="hero" x={[20, -20]} tagOuter="div">
                                <div className="heroContent">
                                    <Lottie
                                        width={screenWidth <= 320 ? 290 : screenWidth <= 360 ? 360 : 500}
                                        height={screenHeight <= 650 ? 110 : 164}
                                        options={defaultOptions} />
                                    <div className="heroContext">
                                        <p>Welcome to workflow, an API created by Paynamics to allow you to integrate different payment methods in your e-commerse website with ease. To know more about workflow, click the discover button below.</p>
                                        <ul className="heroContextUl">
                                            <li><button className="heroContextLiBtn" onClick={this.openDialog}>Sign Up</button></li>
                                            <li>
                                                <Button variant="contained" color="primary" className="subscribeButton">Subscribe</Button>
                                            </li>
   
                                        </ul>
                                    </div>
                                </div>
                            </Parallax>
                        </div>
                    </div>
                </div>
                <div className="heroShape">
                    <img src={shape} alt="" />
                </div>
                <FormStepperComponent
                    openDialog={openDialog}
                />
            </div>
        )
    }
}
export default ApplicationHero