/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// import Link from '@material-ui/core/Link';
// images 
import mobile from '../../../images/application/mobile_0.svg'
import googleplay from '../../../images/application/google_play.svg'
import mobileScreen from '../../../images/screenshot2.png'

const ApplicationBanner = () => {

    return (
        <div className="applicationBannerArea" id="home" >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-12">
                        <Parallax
                            className="bannerContent"
                            x={[10, -10]}
                            tagOuter="div">
                            <h2>EASY INTEGRATION TESTING</h2>
                            <span></span>
                            <p>We don’t want you to go to all over the counter outlets just to test transactions. That’s why we created an OTC simulator payment app. All you have to do is choose a payment channel where you want to simulate payment, then enter the reference number that our API provided on your test, as well as the amount to be paid. And wait for our web-hook to your notification URL to receive the payment status. Download our payment simulator on google play.</p>
                            <div className="appStoreImg" style={{ marginTop: '50px' }}>
                                <a
                                    href={'https://play.google.com/store/apps/details?id=net.paynamics.paychannelsimulator&hl=en'}
                                // component={Button}
                                >
                                    <img src={googleplay}>
                                </img></a>
                            </div>
                        </Parallax>
                    </div>
                    <div className="col-lg-7 col-12">
                        <Parallax
                            className="bannerImg"
                            x={[-10, 10]}
                            tagOuter="div">
                                <img className="bannerPhone" src={mobile} alt="banner" />
                                <img src={mobileScreen} className="mobileContent" alt="sreeen" />
                        </Parallax>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ApplicationBanner