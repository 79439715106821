import React, { Component } from 'react';

import da5 from '../../images/nonbank_otc/da5_ph.svg'
import seven11 from '../../images/nonbank_otc/711_ph.svg'
import smbills from '../../images/nonbank_otc/smbills_ph.svg'
import truemoney from '../../images/nonbank_otc/truemoney_ph.svg'
import expresspay from '../../images/nonbank_otc/expresspay_ph.svg'
import cliqq from '../../images/nonbank_otc/cliqq_ph.svg'
import mlhuillier from '../../images/nonbank_otc/mlhuillier_ph.svg'
import posible from '../../images/nonbank_otc/posible_ph.svg'
import etap from '../../images/nonbank_otc/channel_etap_logo.svg'
import cebuana from '../../images/nonbank_otc/cebuana_ph.svg'
import ecpay from '../../images/nonbank_otc/ecpay_ph.svg'

import alipay from '../../images/wallet/alipay_logo.svg';
import coins from '../../images/wallet/coins_logo.svg';
import gcash from '../../images/wallet/gcash_logo.svg';
import paymaya from '../../images/wallet/paymaya_logo.svg';
import wechat from '../../images/wallet/wechat_logo.svg';

import ubp from '../../images/bank_otc/ubp_ph.svg';
import bpi from '../../images/bank_otc/bpi_logo.svg';


class AllPartnersComponent extends Component {


    render() {
        const featureds = [
            {
                image: smbills,
            },
            {
                image: seven11,
            },
            {
                image: truemoney,
            },
            {
                image: expresspay,
            },
            {
                image: cliqq,
            },
            {
                image: mlhuillier,
            },
            {
                image: posible,
            },
            {
                image: etap,
            },
            {
                image: cebuana,
            },
            {
                image: ecpay,
            },
            {
                image: da5,
            },
            {
                image: alipay,
            },
            {
                image: coins,
            },
            {
                image: gcash,
            },
            {
                image: paymaya,
            },
            {
                image: wechat,
            },
            {
                image: ubp,
            },
            {
                image: bpi,
            }
        ];
        const resizeLarge = `@media only screen and (min-width: 300px) and (max-width: 767px) {
            #partnersDiv {
              position: relative;
              right: 96px;
            }
            .MuiTabs-root-14 {
                position: relative;
                left: -7px;
            }
          }`;
          
        // const resize768 = `@media only screen and (min-width: 768px) and (max-width: 1439px) {
        //     #partnersDiv {
        //       position: relative;
        //       right: 35px;
        //     }
        //     .MuiTabs-root-14 {
        //         position: relative;
        //         left: 13px;
        //     }
        //   }`;
          
        return (
            <div className="row no-gutters" id="partnersDiv">
                <style>{resizeLarge}</style>
                {featureds.map((featured, i) => (
                    <div key={i} className="col-4 col-md-3 col-xs-6 col-sm-12 ml-1" id="iconPartners">
                        <div className="applicationPartnerWrap">

                            <div className="partnerIcon">
                                <img src={featured.image} alt="" style={{ width: '150px' }} />
                            </div>
                            <h3>{featured.title}</h3>
                            <p>{featured.text}</p>
                        </div>
                    </div>
                ))}
            </div >
        )
    }
}
export default AllPartnersComponent