import React, { Component } from 'react';
import alipay from '../../images/wallet/alipay_logo.svg';
import coins from '../../images/wallet/coins_logo.svg';
import gcash from '../../images/wallet/gcash_logo.svg';
import paymaya from '../../images/wallet/paymaya_logo.svg';
import wechat from '../../images/wallet/wechat_logo.svg';

class WalletComponent extends Component {
    render() {
        const featureds = [
            {
                image: alipay,
            },
            {
                image: coins,
            },
            {
                image: gcash,
            },
            {
                image: paymaya,
            },
            {
                image: wechat,
            },
        ];
        // const resize = `
        // .no-gutters{
        //     position:relative;
        //     right:35px;
        // }
        // @media only screen and (max-width: 750px) {
        //     .no-gutters {
        //       position: relative;
        //       right: 40px;
        //     }
        //     .MuiTabs-flexContainer-15 {
        //         position:relative;
        //         left:9px;
        //     }
        // }`;
        const resizeLarge = `@media only screen and (min-width: 300px) and (max-width: 767px) {
            #partnersDiv {
              position: relative;
              right: 96px;
            }
            .MuiTabs-root-14 {
                position: relative;
                left: -7px;
            }
          }`;
          
        const resize768 = `@media only screen and (min-width: 768px) and (max-width: 1439px) {
            #partnersDiv {
              position: relative;
              right: 35px;
            }
            .MuiTabs-root-14 {
                position: relative;
                left: 13px;
            }
          }`;
        return (
            <div className="row no-gutters" id="partnersDiv">
                <style>{resizeLarge}{resize768}</style>
                {featureds.map((featured, i) => (
                    <div key={i} className="col-4 col-md-3 col-xs-6 col-sm-12 ml-1" id="iconPartners">

                        <div className="applicationPartnerWrap">

                            <div className="partnerIcon">
                                <img src={featured.image} alt="" style={{ width: '150px' }} />
                            </div>
                            <h3>{featured.title}</h3>
                            <p>{featured.text}</p>
                        </div>
                    </div>
                ))}
            </div >
        )
    }
}
export default WalletComponent