import React from 'react'

import Lottie from 'react-lottie';
// images 
import featured1 from '../../../images/application/icons/cc.svg'
import featured2 from '../../../images/application/icons/otc.svg'
import featured3 from '../../../images/application/icons/wallet.svg'
import featured4 from '../../../images/application/icons/obt.svg'
import featured5 from '../../../images/application/icons/obp.svg'
// import shape from '../../../images/application/shape/shape6.svg'
import * as mapBanner from '../../../images/lottie/map_banner.json'

const featureds = [
    {
        image: featured1,
        title: 'Creditcard',
        text: 'Let your business receive credit card payments from VISA, MASTERCARD, and many more.'
    },
    {
        image: featured2,
        title: 'Over-the-Counter',
        text: 'Let your customer pay their orders using our payment partners all over the country.'
    },
    {
        image: featured3,
        title: 'E-Wallet',
        text: 'Let your business receive payment through E-wallet such as G-Cash, Coins, any many more.'
    },
    {
        image: featured4,
        title: 'Online Bank Transfer',
        text: 'Let your business receive customer payment directly from their bank account through online transfer.'
    },
    {
        image: featured5,
        title: 'Online Bills Payment',
        text: 'Let your business receive customer payment directly from internet/mobile banking.'
    },
]

const ApplicationFeatured = () => {
    const mapOptions = {
        loop: true,
        autoplay: true,
        renderer: 'svg',
        animationData: mapBanner.default,
        rendererSettings: {
            scaleMode: 'noScale',
        }
    };
    return (
        <div className="applicationFeaturedArea" id="discover" >
            <div className="container">
                <div className="col-13 col-md-12 col-s-12 row">
                    <div className="col-lg-6 col-12 featured-headline">
                        <div className="count-desc">By integrating with Paynamic's workflow, your business<br />can accept payments from over</div>
                        <div className="channel-count">32,000</div>
                        <div className="count-desc">payment channels all over the country.<br />These includes</div>
                    </div>
                    <div className="col-lg-6 col-12 channel-shape">
                        <Lottie
                            height="100%"
                            width="100%"
                            options={mapOptions} />
                    </div>
                </div>
                <div className="row no-gutters">
                    {featureds.map((featured, i) => (
                        <div key={i} className="col-lg-3 col-md-6 col-sm-12" >
                            <div className="applicationFeaturedWrap">
                                <div className="featuredIcon" style={{ backgroundColor: '#ffffff00'}}>
                                    <img src={featured.image} alt="" />
                                </div>
                                <h3>{featured.title}</h3>
                                <p className="txt">{featured.text}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default ApplicationFeatured