
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import axios from 'axios';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableRow } from '@material-ui/core';
import step1 from '../../components/FormStepper/step1avatar.svg';
import step2a from '../../components/FormStepper/step2avatar.svg';
import step2b from '../../components/FormStepper/rightpanelbg.svg';
import step3 from '../../components/FormStepper/step3avatar.svg';
import checkmark from '../../components/FormStepper/check_mark.svg';

class FormStepperComponent extends Component {
    state = {
        time: 300,
        timer: null,
        isFetching: false,
        invalidOtp: false,
        setFormState: false,
        openDialog: false,
        hideDialog: false,
        validateOtp: false,
        otpResponseRaw: '',
        otpResponseValue: '',
        merchantResponseRaw: '',
        merchantResponseValue: '',
        openConfirmationDialog: false,
        openErrorDialog: false,
        activeStep: 0,
        steps: ['Your Company Details', 'Verify OTP', 'Registration Complete'],
        phoneFormatted: '+63 9',
        mobileNum: '',
        companyDetails: {
            name: '',
            email: '',
            mobile_no: '',
            company_name: '',
            nature_of_business: '',
            company_address: '',
            company_website: '',
            tin: '',
            prefix: '',
        },
        otp: '',
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.openDialog) {
            this.setState({ setFormState: false });
            this.setState({ hideDialog: false });
        }
        console.log('NEXT RECEIVE PROPS', nextProps.openDialog);
    }

    header = () => {
        const username = 'administrator';
        const password = '123@123';

        console.log('USER', username);
        console.log('PASS', password);
        const accountHash = window.btoa(username + ":" + password);
        console.log('ACOUNT HASH', accountHash);
        const summaryHeaders = {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': `Basic ${accountHash}`,
            // 'Access-Control-Allow-Origin:': '*',
        };
        return summaryHeaders;
    }


    async saveOtp(value) {
        const reqHeader = this.header();
        console.log('REQ HEADER', reqHeader);
        console.log('VALUE', value);
        const mandrakeOTPUrl = 'https://workflow.payserv.net/tesseract/sandboxotps';
        // const mandrakeOTPUrl = 'http://localhost:3004/paygate/sandboxotps';
        const sendOTP = await axios({
            method: 'post',
            url: mandrakeOTPUrl,
            headers: reqHeader,
            data: value
        }).then(async (response) => {
            console.log('RESPONSE', response);
            return await response;
        }).catch((error) => {
            console.log('ERROR ON SANDBOX OTP GET AUTHORIZE', error);
            return error;
        });
        this.state.otpResponseRaw = sendOTP;

        if (this.state.activeStep === 1) {
            // this is for the timer
            this.state.timer = setInterval(() => this.setState({
                time: this.state.time - 1,
            }), 1000);
        }
        console.log('OTP RETURN', this.state.otpResponseRaw);
    }

    async resendOtp(value) {
        const reqHeader = this.header();
        console.log('REQ HEADER', reqHeader);
        console.log('VALUE', value);
        // const mandrakeUpdateOTPUrl = 'http://localhost:3004/paygate/sandboxotps/otp/mobile';
        const mandrakeUpdateOTPUrl = 'https://workflow.payserv.net/tesseract/sandboxotps/otp/mobile/';
        const updateOTP = await axios({
            method: 'put',
            url: mandrakeUpdateOTPUrl,
            headers: reqHeader,
            data: value
        }).then(async (response) => {
            console.log('RESPONSE', response);
            return await response;
        }).catch((error) => {
            console.log('ERROR ON SANDBOX OTP GET AUTHORIZE', error);
            return error;
        });
        console.log('UPDATE OTP RESPONSE', updateOTP);
        this.state.otpResponseRaw = updateOTP;

        if (this.state.activeStep === 1) {
            // this is for the timer
            this.state.timer = setInterval(() => this.setState({
                time: this.state.time - 1,
            }), 1000);
        }
        console.log('OTP RETURN', this.state.otpResponseRaw);
    }


    async saveMerchant(value) {
        const reqHeader = this.header();
        console.log('REQ HEADER', reqHeader);
        // const mandrakeMerchantURL = `${reqHeader+'sandboxmerchants'}`;
        const mandrakeMerchantURL = 'https://workflow.payserv.net/tesseract/sandboxmerchants';
        // const mandrakeMerchantURL = 'http://localhost:3004/paygate/sandboxmerchants';
        const saveMerchantDetails = await axios({
            method: 'post',
            url: mandrakeMerchantURL,
            headers: reqHeader,
            data: value
        }).then(async (response) => {
            console.log('RESPONSE', response);
            return await response;
        }).catch((error) => {
            console.log('ERROR ON SANDBOX MERCHANT AUTHORIZE', error);
            return null;
        });
        this.state.merchantResponseRaw = saveMerchantDetails;
        console.log('MERCHANT VALUES', this.state.merchantResponseRaw);
        return saveMerchantDetails;
    }

    onReset = () => {
        console.log('RESETING DATAS');
        this.setState({
            companyDetails: {
                name: '',
                email: '',
                mobile_no: '',
                company_name: '',
                nature_of_business: '',
                company_address: '',
                company_website: '',
                tin: '',
                prefix: '',
            },
            otp: '',
            phoneFormatted: '+63 9',
            mobileNum: '',
            setFormState: false,
            openDialog: false,
            hideDialog: true,
            validateOtp: false,
            invalidOtp: false,
            time: 300,
            timer: null,
        });
        clearInterval(this.state.timer);
    }

    showDialog = () => {
        window.history.pushState({}, "SIGNUP", "/signup");
        this.setState({ setFormState: true });
        this.setState({ activeStep: 0 });
    }

    hideDialog = () => {
        console.log('HIDING DIALOG');
        window.history.pushState({}, "HOME", "/");
        this.onReset();
    }

    hideConfirmDialog = () => {
        this.setState({ openConfirmationDialog: false });
    }

    handleNext = () => {
        console.log('BEFORE ACTIVE STEP', this.state.activeStep);
        this.setState({ openConfirmationDialog: false });
        // this.setState({ activeStep: this.state.activeStep + 1 });
        if (this.state.activeStep === 0) {
            this.setState({ activeStep: this.state.activeStep + 1 });
            const mobileToSend = {
                mobile_no: this.state.companyDetails.mobile_no,
            };
            this.saveOtp(mobileToSend);
        }
        else if (this.state.activeStep === 1) {
            const otpResponse = this.state.otpResponseRaw ? this.state.otpResponseRaw.data : '';
            this.setState({ otpResponseValue: otpResponse })
            this.setState({ validateOtp: true })
            console.log('OTP RESPONSE', otpResponse);
            console.log('OTP VALUE', this.state.otp);
            const companyDetails = this.state.companyDetails;
            console.log('COMPANY VALUES', companyDetails);
            console.log('CAN SAVE MERCHANT 1', otpResponse !== null && companyDetails.mobile_no === otpResponse.mobileNo && this.state.otp === otpResponse.otp);
            console.log('CAN SAVE MERCHANT 2', companyDetails.mobile_no === otpResponse.mobileNo);
            console.log('CAN SAVE MERCHANT 3', this.state.otp === otpResponse.otp);
            console.log('CAN SAVE MERCHANT 4', this.state.activeStep === 1);
            console.log('CAN SAVE MERCHANT 5', otpResponse !== null);
            if (otpResponse !== null && companyDetails.mobile_no === otpResponse.mobileNo && this.state.otp === otpResponse.otp && this.state.otp.length === 6) {
                console.log('SAVING COMPANY FORM DATA');
                this.setState({ activeStep: this.state.activeStep + 1 });
                const newCompanyDetails = {
                    name: companyDetails ? companyDetails.name.trim() : '',
                    email: companyDetails ? companyDetails.email.trim() : '',
                    mobile_no: companyDetails ? companyDetails.mobile_no.trim() : '',
                    company_name: companyDetails ? companyDetails.company_name.trim() : '',
                    nature_of_business: companyDetails ? companyDetails.nature_of_business.trim() : '',
                    company_address: companyDetails ? companyDetails.company_address.trim() : '',
                    company_website: companyDetails ? companyDetails.company_website.trim() : '',
                    tin: companyDetails ? companyDetails.tin.trim() : '',
                    prefix: companyDetails ? companyDetails.prefix.trim() : "",
                }
                this.saveMerchant(newCompanyDetails);
            } else {
                this.setState({ invalidOtp: true });
            }
        }

    };

    async proceed(checkData) {
        await this.saveMerchant(checkData);

        const companyDetails = this.state.companyDetails;
        // eslint-disable-next-line
        const emailLegit = companyDetails ? companyDetails.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim) : '';

        const tinValidation = companyDetails && companyDetails.tin.length !== 15;
        console.log('VALIDATION 1', tinValidation);

        const mobileValidation = companyDetails && companyDetails.mobile_no.substring(0, 3) !== '639';
        console.log('VALIDATION 2', mobileValidation);
        const mobileValidation2 = companyDetails && companyDetails.mobile_no.length !== 12;
        console.log('VALIDATION 3', mobileValidation2);

        // const emailValidation = emailLegit !== null && companyDetails && emailLegit[0];
        const emailValidation = emailLegit !== null;
        console.log('VALIDATION 4', emailValidation);

        // const prefixValidation = companyDetails && companyDetails.prefix.length !== 3;
        // console.log('VALIDATION 5', prefixValidation);

        // this.setState({ openConfirmationDialog: true });
        const merchantResponse = this.state.merchantResponseRaw ? this.state.merchantResponseRaw.data : null;
        console.log('MERCHANT RESP VALUE', merchantResponse);
        console.log('VALIDATION 6', this.state.merchantResponseRaw ? merchantResponse.data.isExisting : null);
        this.setState({ merchantResponseValue: merchantResponse ? merchantResponse.data : null });
        console.log('VALIDATION 7', this.state.merchantResponseValue);
        // if (this.state.merchantResponseValue && merchantResponse.data.isExisting === true || tinValidation || mobileValidation || mobileValidation2 || !emailValidation || prefixValidation) {
        if ((this.state.merchantResponseValue && merchantResponse.data.isExisting === true) || tinValidation || mobileValidation || mobileValidation2 || !emailValidation) {
            this.setState({ openErrorDialog: true });
            this.setState({ isFetching: false });
        } else {
            this.setState({ openConfirmationDialog: true });
            this.setState({ isFetching: false });
            console.log('SHOWING ERROR');
        }
    }

    handleConfirmationDialog = () => {
        // console.log('MERCHANT VALUES', this.state.merchantResponseRaw);
        if (this.state.activeStep === 0) {
            const companyDetails = this.state.companyDetails;
            const checkData = {
                name: companyDetails.name,
                email: companyDetails.email,
                mobile_no: companyDetails.mobile_no,
                company_name: companyDetails.company_name,
                nature_of_business: companyDetails.nature_of_business,
                company_address: companyDetails.company_address,
                company_website: companyDetails.company_website,
                tin: companyDetails.tin,
                prefix: companyDetails.prefix,
                isExisting: true,
            }
            this.setState({ isFetching: true });
            this.proceed(checkData);
        }

    }

    formatTimer = () => {
        const minutes = Math.floor(this.state.time / 60);
        const seconds = this.state.time - minutes * 60;
        const formatSeconds = seconds < 10 ? `0${seconds}` : seconds;
        const fullTime = `${minutes}:${formatSeconds}`;
        console.log('FULL TIME', fullTime);
        return fullTime;
    }

    componentDidUpdate() {
        if (this.state.time <= 0) {
            console.log('TIME ZERO');
            clearInterval(this.state.timer);
        }
    }

    componentDidMount() {
        this.setState({ activeStep: 1 });
    }

    resendOTP = () => {
        const otpResponse = this.state.otpResponseRaw.data;
        console.log('OTP RESPONSE AT RESEND', this.state.otpResponseRaw.data);
        const resendValue = {
            id: otpResponse.Id,
        };
        console.log('RESEND VALUE', resendValue);
        this.resendOtp(resendValue)
        this.setState({
            time: 300,
        })
    }

    closeErrorDiag = () => {
        this.setState({ openErrorDialog: false });
    }

    handleChange = (name) => event => {
        if (name !== 'otp' && name !== 'mobile_no' && name !== 'prefix' && name !== 'tin') {
            this.setState({
                companyDetails: {
                    ...this.state.companyDetails,
                    ...{ [name]: event.target.value },
                },
            });
        } else if (name === 'mobile_no') {
            let validation = event.target.value.replace(/[^0-9]/gim, '');
            let finalValue;
            if (validation.length >= 1) {
                // if (this.state.companyDetails.mobile_no.length === 5) {
                //     validation = validation.substring(0,5) + '-' + validation.substring(5,8);
                //     console.log('VALIDATON SUBSTRING 1', validation);
                // } else if (this.state.companyDetails.mobile_no.length === 8) {
                //     validation = validation + '-' + validation.substring(validation.length - 1, validation.length);
                //     console.log('VALIDATON SUBSTRING 2', validation);
                // }
                // const isZero = validation.substring(0, 1);
                // console.log('IS ZERO', isZero);
                // if (isZero === '0') {
                //     formatNum = validation.replace(/^[0-9]{1}/gim, '+63');
                //     finalValue = formatNum ? formatNum : '';
                //     this.setState({ phoneFormatted: formatNum })
                //     console.log('FORMATTED VALUE', formatNum);
                //     console.log('FINAL_VALUE 1', finalValue);
                // } else if (isZero === '6') {
                //     console.log('IS SIX VALUE');
                //     const remaining = validation.substring(2, validation.length);
                //     // const remaining = validation.substring(1, validation.length);
                //     // dashed = remaining.replace(/(\d{3})(?=\d{2})/g, '$1-');
                //     // console.log('DASHED VALUE', dashed);
                //     const newValue = remaining ? remaining.match(/\d{3}(?=\d{2,4})|\d+/g).join("-") : '';
                //     console.log('NEW_VALUE', newValue);
                //     console.log('FINAL_VALUE 3', this.state.phoneFormatted + ' ' + newValue);
                //     const lastValue = this.state.phoneFormatted + ' ' + newValue;
                //     // finalValue = lastValue;
                //     finalValue = lastValue ? lastValue : '';

                // } else {
                console.log('FINAL_VALUE 2', this.state.phoneFormatted);
                const remaining = validation.substring(3, validation.length);
                // dashed = remaining.replace(/(\d{3})(?=\d{2})/g, '$1-');
                // console.log('DASHED VALUE', dashed);
                const newValue = remaining ? remaining.match(/\d{2}(?=\d{6})|(\d{3})(?=\d{2})|\d+/g).join("-") : '';
                console.log('NEW_VALUE', newValue);
                console.log('FINAL_VALUE 3', this.state.phoneFormatted + newValue);
                const lastValue = this.state.phoneFormatted + newValue;
                // finalValue = lastValue;
                finalValue = lastValue ? lastValue : '';
                // }
                // const remaining = validation.substring(2, validation.length);
                // finalValue = `${format + remaining}`
                // const finalValue = format + validation
                // console.log('FINAL VALUE', finalValue);
                // const firstTwo = event.target.value.replace(/^[0-9]{2}/gim, '63');
            } else {
                finalValue = validation;
            }
            // } else {
            //     const remaining = validation.substring(2, validation.length);
            //     dashed = remaining.replace(/(\d{3})(?=\d{2})/g, '$1-');
            //     console.log('DASHED VALUE', dashed);
            //     const newValue = remaining ? remaining.match(/\d{3}(?=\d{2,4})|\d+/g).join("-") : '';
            //     console.log('FORMAT NUM VALUE', formatNum);
            //     finalValue = newValue;
            //     console.log('FINAL_VALUE', finalValue);
            //     // format = validation;
            // }
            // console.log('MOBILE VALIDATION', validation);
            // const newValue = validation.replace(/^0/, '63')
            // console.log('MOBILE NEW VALUE', newValue);
            const removeSpace = finalValue.replace(/\s/g, '');
            const normalValue = removeSpace.replace(/[^a-zA-Z0-9 ]/g, '');
            console.log('NORMAL VALUE', normalValue);
            this.setState({ mobileNum: finalValue })
            this.setState({
                companyDetails: {
                    ...this.state.companyDetails,
                    ...{ [name]: normalValue },
                },
            });
            // console.log('GETTING THE FOURTH', this.state.companyDetails.mobile_no.length);

        } else if (name === 'prefix') {
            // console.log('NAME IS PREFIX', name);
            const value = event.target.value.replace(/\W/gim, '');
            // console.log('GETTING THE VALUE', value);
            this.setState({
                companyDetails: {
                    ...this.state.companyDetails,
                    ...{ [name]: value },
                },
            });
        } else if (name === 'tin') {
            // console.log('NAME IS PREFIX', name);
            const validate = event.target.value.replace(/[^0-9]/g, '');
            const newValue = validate ? validate.match(/\d{3}(?=\d{2,4})|\d+/g).join("-") : '';
            // console.log('NEWVALUE', newValue.length);
            this.setState({
                companyDetails: {
                    ...this.state.companyDetails,
                    ...{ [name]: newValue },
                },
            });
            // console.log('GETTING THE FOURTH', this.state.companyDetails.tin.length);
        }
        else {
            this.setState({ otp: event.target.value });
        }
        // console.log('COMPANY DETAILS VALUE', this.state.companyDetails);
    }

    render() {
        const { companyDetails, mobileNum, otp, invalidOtp, activeStep, isFetching, steps, setFormState, hideDialog, timer, merchantResponseValue, openConfirmationDialog, openErrorDialog } = this.state;
        // eslint-disable-next-line
        const emailLegit = companyDetails ? companyDetails.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim) : '';
        // console.log('EMAIL VALUE', emailLegit);
        //     const otpLabel = `.MuiFormLabel-root-152.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-marginDense-149 {
        //         // position:relative;
        //         left: 13px;
        //         font-size: 9.5px;
        //     }`;
        //     const otpLabelEdit = `
        //     @media only screen and (min-width: 414px) {
        //         .MuiFormLabel-root-152.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-marginDense-149 {
        //             // position: relative;
        //             left: 18px;
        //             font-size: 12px;
        //         }
        //     }`;
        //     // const otpLabel768 = `
        //     // @media only screen and (min-width: 768px) {
        //     //     .MuiFormLabel-root-152.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-marginDense-149 {
        //     //         position: relative;
        //     //         left: 30px;
        //     //         font-size: 13px;
        //     //     }
        //     // }`;
        //     const otpLabel1024 = `
        //     @media only screen and (min-width: 1024px) {
        //         .MuiFormLabel-root-152.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-marginDense-149 {
        //             // position: relative;
        //             left: 43px;
        //             font-size: 15px;
        //         }
        //     }`;
        //     const otpLabel375 = `
        //     @media only screen and (max-width: 375px) {
        //         .MuiFormLabel-root-152.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-marginDense-149 {
        //             // position: relative;
        //             left: 14px;
        //             font-size: 9.5px;
        //         }
        //         .MuiFormLabel-root-152.MuiFormLabel-filled-156.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-shrink-150.MuiInputLabel-marginDense-149 {
        //             // position: relative;
        //             left: 25px;
        //         }
        //     }`;
        //     // const input768 = ` @media only screen and (min-width: 768px) {
        //     //     .MuiFormControl-root-143.MuiFormControl-marginDense-145.MuiFormControl-fullWidth-146.otpTextField {
        //     //         position: relative;
        //     //         left: -35px;
        //     //     }
        //     // }`;
        //     const input1440 = ` @media only screen and (min-width: 1440px) {
        //         .MuiFormControl-root-143.MuiFormControl-marginDense-145.MuiFormControl-fullWidth-146.otpTextField {
        //             // position: relative;
        //             left: 0px;
        //         }
        //     }`;
        //     const input1024 = ` @media only screen and (min-width: 1024px) {
        //         .MuiFormControl-root-143.MuiFormControl-marginDense-145.MuiFormControl-fullWidth-146.otpTextField {
        //             // position: relative;
        //             left: -4px;
        //         }
        //     }`;
        //     const input375 = ` @media only screen and (max-width: 375px) {
        //         .MuiFormControl-root-143.MuiFormControl-marginDense-145.MuiFormControl-fullWidth-146.otpTextField {
        //             // position: relative;
        //             right: 10px;
        //         }
        //     }`;
        //     const inputPos = `.MuiInput-root-159.MuiInput-fullWidth-166.MuiInput-formControl-160.MuiInput-underline-163 {
        //         // position: relative;
        //         left: 11px;
        //     }`;
        //     const inputPosEdit = `@media only screen and (min-width: 414px) {
        //         .MuiInput-root-159.MuiInput-fullWidth-166.MuiInput-formControl-160.MuiInput-underline-163 {
        //         // position: relative;
        //         left: 17px;
        //         top: -17px
        //     }
        // };`
        //     const shrinkedLabel = `.MuiFormLabel-root-152.MuiFormLabel-focused-153.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-shrink-150 {
        //         // position: relative;
        //         left: 22px;
        //     }`;
        // //     const shrinkEdit = `@media only screen and (min-width: 414px) {
        // //         .MuiFormLabel-root-152.MuiFormLabel-focused-153.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-shrink-150 {
        // //             position: relative;
        // //             left: 30px;
        // //     }
        // // }`;
        // //     const shrink768 = `@media only screen and (min-width: 767px) {
        // //         .MuiFormLabel-root-152.MuiFormLabel-focused-153.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-shrink-150 {
        // //             position: relative;
        // //             left: 44px;
        // //     }
        // // }`;
        //     const shrink375 = `@media only screen and (min-width: 375px) {
        //         .MuiFormLabel-root-152.MuiFormLabel-focused-153.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-shrink-150 {
        //             // position: relative;
        //             left: 23px;
        //     }
        // }`;
        //     const shrink414 = `@media only screen and (min-width: 414px) {
        //         .MuiFormLabel-root-152.MuiFormLabel-focused-153.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-shrink-150 {
        //             // position: relative;
        //             left: 29px;
        //     }
        // }`;
        // //     const shrink768 = `@media only screen and (min-width: 768px) {
        // //         .MuiFormLabel-root-152.MuiFormLabel-focused-153.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-shrink-150 {
        // //             position: relative;
        // //             left: 44px;
        // //     }
        // // }`;
        //     const shrink1024 = `@media only screen and (min-width: 1024px) {
        //         .MuiFormLabel-root-152.MuiFormLabel-focused-153.MuiInputLabel-root-147.MuiInputLabel-formControl-148.MuiInputLabel-animated-151.MuiInputLabel-shrink-150 {
        //             // position: relative;
        //             left: 55px;
        //     }
        // }`;

        //     const errorMsg375 = `@media only screen and (min-width: 375px) {
        //         .divError {
        //             // position: relative;
        //             font-size: 7px;
        //             margin: 10px;
        //             top: -183px;
        //             right: -46px;
        //             font-size: 10px;
        //     }
        //         .MuiSvgIcon-root-102 {
        //             font-size: 15px;
        //         }
        //         .iconStyle {
        //             margin: 0px 5px 0 0;
        //         }
        // }`;
        //     const errorMsg414 = `@media only screen and (min-width: 414px) {
        //         .divError {
        //             position: relative;
        //             font-size: 7px;
        //             margin: 10px;
        //             top: -193px;
        //             right: -60px;
        //             font-size: 10px;
        //     }
        //         .MuiSvgIcon-root-102 {
        //             font-size: 20px;
        //         }
        //         .iconStyle {
        //             margin: 0px 5px 0 0;
        //         }
        // }`;
        //     const errorMsg1024 = `@media only screen and (min-width: 1024px) {
        //         .divError {
        //             position: relative;
        //             font-size: 7px;
        //             margin: 10px;
        //             top: -219px;
        //             right: -127px;
        //             font-size: 16px;
        //     }
        //         .MuiSvgIcon-root-102 {
        //             font-size: 20px;
        //         }
        //         .iconStyle {
        //             margin: 0px 5px 0 0;
        //         }
        // }`;

        const disableNextOne =
            companyDetails.tin === '' ||
            companyDetails.name === '' ||
            // companyDetails.prefix === '' ||
            companyDetails.email === '' ||
            companyDetails.mobile_no === '' ||
            companyDetails.company_name === '' ||
            companyDetails.company_address === '' ||
            companyDetails.company_website === '' ||
            companyDetails.nature_of_business === '';
        // console.log('DISBLE', disableNextOne);
        const disableNextTwo = otp === '';
        // if (this.props.openDialog === true && this.state.setFormState === true) {
        if (this.props.openDialog && !setFormState && !hideDialog) {
            this.showDialog();
        }
        if (activeStep === 2) {
            clearInterval(timer);
        }
        const fullTime = this.formatTimer();
        console.log('TIMER CONDITION', timer === 0);
        console.log('TIMER VALUE', timer);
        console.log('CURRENT ACTIVE STEP', activeStep);
        const merchantData = merchantResponseValue ? merchantResponseValue : '';
        return (
            <div>
                <Dialog
                    className="DialogScroll"
                    onSubmit={this.handleSubmit}
                    open={setFormState}
                    maxWidth={"md"}
                    fullWidth={true}
                >
                    <div className="DialogScroll">
                        <Stepper
                            className="stepperClass"
                            activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step className="DialogScrollStep" key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === 0 ?
                            <div className="dBoxSize">
                                <div className="dBoxForm">
                                    <DialogTitle className="dBoxTitle" id="form-dialog-title">Your Company Details</DialogTitle>
                                    <div className="pMargin">To register, Please fill up the required details below</div>
                                    <DialogContent>
                                        <div>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="Your Name"
                                                fullWidth
                                                tabIndex="0"
                                                value={companyDetails.name}
                                                onChange={this.handleChange('name')}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                className="dBoxCompanyName"
                                                margin="dense"
                                                id="company_name"
                                                label="Your Company Name"
                                                fullWidth
                                                inputProps={{ maxLength: 255 }}
                                                value={companyDetails.company_name}
                                                onChange={this.handleChange('company_name')}
                                            />
                                            {/* <TextField
                                                className="dBoxPrefixError"
                                                margin="dense"
                                                id="prefix"
                                                label="Prefix"
                                                inputProps={{ maxLength: 3 }}
                                                fullWidth
                                                value={companyDetails.prefix}
                                                onChange={this.handleChange('prefix')}
                                            /> */}
                                        </div>
                                        <div>
                                            <TextField
                                                className="dBoxTin"
                                                inputProps={{ maxLength: 15 }}
                                                margin="dense"
                                                id="tin"
                                                label="Company Tin"
                                                fullWidth
                                                value={companyDetails.tin}
                                                onChange={this.handleChange('tin')}
                                            />
                                            <TextField
                                                className="dBoxNOB"
                                                margin="dense"
                                                id="nature_of_business"
                                                label="Nature of Business"
                                                fullWidth
                                                inputProps={{ maxLength: 255 }}
                                                value={companyDetails.nature_of_business}
                                                onChange={this.handleChange('nature_of_business')}

                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                className="dBoxMobile"
                                                margin="dense"
                                                placeholder="+63 9XX-XXX-XXXX"
                                                inputProps={{ maxLength: 16 }}
                                                id="mobile_no"
                                                label="Mobile Number"
                                                fullWidth
                                                value={mobileNum}
                                                onChange={this.handleChange('mobile_no')}
                                            />
                                            <TextField
                                                className="dBoxEmail"
                                                margin="dense"
                                                id="email"
                                                label="Email"
                                                type="email"
                                                inputProps={{ maxLength: 255 }}
                                                fullWidth
                                                value={companyDetails.email}
                                                onChange={this.handleChange('email')}
                                            />
                                        </div>

                                        <TextField
                                            margin="dense"
                                            id="company_website"
                                            label="Company Website"
                                            inputProps={{ maxLength: 255 }}
                                            fullWidth
                                            value={companyDetails.company_website}
                                            onChange={this.handleChange('company_website')}
                                        />
                                        <TextField
                                            margin="dense"
                                            id="company_address"
                                            label="Company Address"
                                            fullWidth
                                            inputProps={{ maxLength: 255 }}
                                            value={companyDetails.company_address}
                                            onChange={this.handleChange('company_address')}

                                        />
                                    </DialogContent>
                                    <div>

                                    </div>
                                </div>
                                <div className="dBoxImg">
                                    <DialogContent>
                                        <div>
                                            <img
                                                height={'100%'}
                                                width={'100%'}
                                                className="imgSize"
                                                src={step1}
                                                alt="avatar"
                                            />
                                        </div>
                                    </DialogContent>
                                </div>
                            </div>
                            : activeStep === 1
                                ?

                                < div className="parentDiv" >

                                    <div className="parentText">
                                        <div style={{ width: '98%' }}>
                                            <DialogTitle id="form-dialog">OTP</DialogTitle>
                                            <p className="pMargin" >We've sent a one-time-password on the mobile number that you nominated on previous step. If you received the code via SMS, please enter the code here.</p>
                                            <img src={step2a} style={{ paddingLeft: '40px' }} alt="avatar2" />
                                        </div>
                                    </div>

                                    <div className="parentOTP">
                                        <DialogContent>
                                            <div className="otpField">
                                                <img src={step2b} alt="avatar2" />
                                                <div className="txtOTP">
                                                    <strong>
                                                        <TextField
                                                            className="otpTextField"
                                                            // style={{ width: '207px', textAlign: 'center', fontSize: '10px'}}
                                                            autoFocus
                                                            margin="dense"
                                                            id="otp"
                                                            label="ENTER YOUR OTP"
                                                            type="number"
                                                            onInput={(e) => {
                                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)

                                                            }}
                                                            fullWidth
                                                            tabIndex="0"
                                                            value={otp}
                                                            onChange={this.handleChange('otp')}
                                                        />

                                                    </strong>
                                                </div>
                                                {invalidOtp ?
                                                    <div className="divError">
                                                        <ErrorOutlineOutlinedIcon className="iconStyle" />
                                                        Invalid OTP, please try again.
                                                    </div>
                                                    : ''
                                                }
                                                {fullTime === '0:00' ?
                                                    <Button
                                                        className="resendBtn"
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={this.resendOTP}
                                                    >
                                                        Resend One Time Password
                                                    </Button>
                                                    : ''
                                                }
                                            </div>
                                        </DialogContent>
                                    </div>
                                </div>
                                : activeStep === 2
                                    ?
                                    <div className="parentDiv">
                                        <div className="parentDivImage">

                                            <DialogTitle id="form-dialog">Registration Complete</DialogTitle>
                                            <div className="parentDivText1">Congratulations, you have successfully created a workflow account</div>
                                            <img src={step3} className="parentDivImg" alt="avatar" />
                                        </div>
                                        <div className="parentDivText">
                                            <div className="parentDivTextValue">
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>We have created 2 accounts for you. One as an administrator access and one for developer access.</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>Use your administrator access to monitor your testing. Such as test transaction status etc.</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>Use your developer access to view our online API documentation. This will help you integrate with workflow easily</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>We have sent email to {companyDetails.email} about the details of your workflow access</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''

                        }
                    </div>
                    <DialogActions className="moreActionDialog">
                        <div className="footerBase">
                            <div className="footerDiv">
                                {activeStep === 0 ?
                                    <Button onClick={activeStep === 0 ? this.hideDialog : ''}>
                                        {'Exit'}
                                    </Button>
                                    : ''
                                }
                                <Button
                                    disabled={isFetching ? isFetching : activeStep === 0 ? disableNextOne : activeStep === 1 ? disableNextTwo : ''}
                                    variant="contained"
                                    color="primary"
                                    onClick={activeStep === 0 ? this.handleConfirmationDialog : activeStep === 1 ? this.handleNext : activeStep === 2 ? this.hideDialog : ''}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openConfirmationDialog}
                >
                    <DialogTitle id="form-dialog-title">CONFRIMATION</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Company Name
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.company_name}
                                    </TableCell>
                                </TableRow>
                                {/* <TableRow>
                                    <TableCell>
                                        Prefix
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.prefix}
                                    </TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TableCell>
                                        TIN
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.tin}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Nature of Business
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.nature_of_business}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Mobile Number
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.mobile_no}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Email
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Company Website
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.company_website}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Company Address
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.company_address}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.hideConfirmDialog}
                        >
                            CANCEL
                        </Button>
                        <Button
                            onClick={this.handleNext}
                            variant="contained"
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className="errorDialog"
                    maxWidth={"sm"}
                    // disableBackdropClick={true}
                    open={openErrorDialog}
                >
                    <DialogContent>
                        {companyDetails && companyDetails.tin.length !== 15 ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                TIN must have 12 characters.
                            </div>
                            : ''
                        }
                        {companyDetails && companyDetails.mobile_no.substring(0, 3) !== '639' ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                {'Invalid mobile number format.\nMobile number should start with 63'}
                            </div>
                            : ''
                        }
                        {companyDetails && companyDetails.mobile_no.length !== 12 ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                Mobile No must have 12 characters.
                            </div>
                            : ''
                        }
                        {/* {companyDetails && companyDetails.prefix.length !== 3 ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                Prefix must have 3 characters
                            </div>
                            : ''
                        } */}
                        {!emailLegit ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                Invalid email.
                            </div>
                            : ''
                        }
                        {/* {merchantData && merchantData.prefix ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                Prefix Already Exist
                            </div>
                            : ''
                        } */}

                        {merchantData && merchantData.tin ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                TIN Already Exist
                            </div>
                            : ''
                        }

                        {merchantData && merchantData.companyEmail ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                Email Already Exist
                            </div>
                            : ''
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.closeErrorDiag}
                            variant="contained"
                            color="primary"
                        >
                            OK
                    </Button>
                    </DialogActions>


                </Dialog>
            </div >
        )
    }
}
export default FormStepperComponent