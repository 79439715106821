import React, { Component } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AllPartnersComponent from './allPartners';
import OverTheCounterComponent from './overTheCounter';
import WalletComponent from './wallet';
import BankTransferComponent from './bankTransfer';
import './style.scss'

class PartnersTabComponent extends Component {
    state = {
        value: 0,
    }


    handleChange = (event, newValue) => {
        console.log('EVENT', event);
        console.log('NEW_VALUE', newValue);
        this.setState({value: newValue});
    }

    render() {
        const { value } = this.state;
        return (
            <div style={{ width: '100%' }} >
                <Tabs
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered={true}
                >
                    <Tab className="col-3" label="ALL" />
                    <Tab className="col-3" label="OVER THE COUNTER" />
                    <Tab className="col-3" label="WALLET" />
                    <Tab className="col-3" label="BANK TRANSFER" />
                </Tabs>
                {value === 0 ? <AllPartnersComponent/> : ''}
                {value === 1 ? <OverTheCounterComponent/> : ''}
                {value === 2 ? <WalletComponent/> : ''}
                {value === 3 ? <BankTransferComponent/> : ''}
            </div >
        )
    }
}
export default PartnersTabComponent