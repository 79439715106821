import React, { Component } from 'react';
import da5 from '../../images/nonbank_otc/da5_ph.svg'
import seven11 from '../../images/nonbank_otc/711_ph.svg'
import smbills from '../../images/nonbank_otc/smbills_ph.svg'
import truemoney from '../../images/nonbank_otc/truemoney_ph.svg'
import expresspay from '../../images/nonbank_otc/expresspay_ph.svg'
import cliqq from '../../images/nonbank_otc/cliqq_ph.svg'
import mlhuillier from '../../images/nonbank_otc/mlhuillier_ph.svg'
import posible from '../../images/nonbank_otc/posible_ph.svg'
import etap from '../../images/nonbank_otc/channel_etap_logo.svg'
import cebuana from '../../images/nonbank_otc/cebuana_ph.svg'
import ecpay from '../../images/nonbank_otc/ecpay_ph.svg'

class OverTheCounterComponent extends Component {
    render() {
        const featureds = [
            {
                image: smbills,
            },
            {
                image: seven11,
            },
            {
                image: truemoney,
            },
            {
                image: expresspay,
            },
            {
                image: cliqq,
            },
            {
                image: mlhuillier,
            },
            {
                image: posible,
            },
            {
                image: etap,
            },
            {
                image: cebuana,
            },
            {
                image: ecpay,
            },
            {
                image: da5,
            }
        ];
        // const resize = `
        // // .no-gutters{
        // //     position:relative;
        // //     right:35px;
        // // }
        // // @media only screen and (max-width: 750px) {
        // //     .no-gutters {
        // //       position: relative;
        // //       right: 40px;
        // //     }
        // //     .MuiTabs-flexContainer-15 {
        // //         position:relative;
        // //         left:9px;
        // //     }
        // // }`;
        const resizeLarge = `@media only screen and (min-width: 300px) and (max-width: 767px) {
            #partnersDiv {
              position: relative;
              right: 96px;
            }
            .MuiTabs-root-14 {
                position: relative;
                left: -7px;
            }
          }`;
          
        const resize768 = `@media only screen and (min-width: 768px) and (max-width: 1439px) {
            #partnersDiv {
              position: relative;
              right: 35px;
            }
            .MuiTabs-root-14 {
                position: relative;
                left: 13px;
            }
          }`;
        return (
            <div className="row no-gutters" id="partnersDiv">
                <style>{resizeLarge}{resize768}</style>
                {featureds.map((featured, i) => (
                    <div key={i} className="col-4 col-md-3 col-xs-6 col-sm-12 ml-1" id="iconPartners">

                        <div className="applicationPartnerWrap">

                            <div className="partnerIcon">
                                <img src={featured.image} alt="" style={{ width: '150px' }} />
                            </div>
                            <h3>{featured.title}</h3>
                            <p>{featured.text}</p>
                        </div>
                    </div>
                ))}
            </div >
        )
    }
}
export default OverTheCounterComponent