import React from 'react'
import PartnersTabComponent from '../../../components/PartnersTab';

// import shape from '../../../images/application/shape/shape6.svg'
// import * as mapBanner from '../../../images/lottie/map_banner.json'

const ApplicationPartners = () => {
    // const mapOptions = {
    //     loop: true,
    //     autoplay: true,
    //     renderer: 'canvas',
    //     animationData: mapBanner.default,
    //     rendererSettings: {
    //         preserveAspectRatio: 'xMidYMid slice'
    //     }
    // };
  
    return (
        <div className="applicationPartnerArea" id="discover" >
            <div className="container">
                <div id="headline" className="col-lg-12 col-12 featured-headline">
                    <h1 align="center"> Our Trusted Partners</h1>
                    <p align="center">Our payment partners that will process your payment</p>
                    <div className="row">
                    </div>
                </div>
            </div>
            <div className="row no-gutters">
                <PartnersTabComponent />
            </div>


        </div>
    )
}
export default ApplicationPartners