import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-scroll';
import Sticky from 'react-sticky-el';
// import DialogOpen from './signup.js'

import FormStepperComponent from '../../../components/FormStepper'
import logo from '../../../images/logo2.svg'
import logoDark from '../../../images/logo.svg'

// const mainMenus = ['home', 'discover', 'integration', 'signup']
const mainMenus = ['home', 'discover', 'signup', 'login']

class ApplicationHeader extends Component {
    state = {
        setTrue: false,
        responsiveMenu: false,
    }

    setResponsiveMenu = () => {
        this.setState({ setTrue: false });
        if (this.state.responsiveMenu) {
            this.setState({ responsiveMenu: false });
            
        } else if (!this.state.responsiveMenu) {
            this.setState({ responsiveMenu: true });

        }
    }
    setResponsiveMenuFalse = () => {
    }

    componentDidMount() {
        var url = window.location.pathname;
        console.log('CURRENT LOCATION', url);
        if (url === '/signup') {
            this.openDialog();
        }
    }

    openDialog = () => {
        this.setState({ setTrue: true });
        this.setState({ responsiveMenu: false });
    }
    homeMenu = () => {
        this.setState({ responsiveMenu: false });
    }
    discoverMenu = () => {
        this.setState({ responsiveMenu: false });
    }

    redirectToWorkFlow = () => {
        this.setState({ responsiveMenu: false });
        window.location.href = `https://workflow.payserv.net/`;
    }
    render() {
        let { openDialog } = this.props;
        openDialog = false;
        if (this.state.setTrue) {
            openDialog = true;
        }
        // else {
        //     const openStatus = this.onLoad(openDialog);
        //     openDialog = openStatus;
        // }
        console.log('PROPS VALUES RENDER', this.props);

        console.log('OPEN DIALOG VALUE', openDialog);
        return (
            <div className="portfolioMainArea">
                <Sticky holderCmp='header' className="headerArea agencyHeaderArea portfolioHeaderArea applicationHeaderArea">
                    <div className="container">
                        <Row>
                            <Col lg={6} sm={11} xs={8}>
                                <div className="logo">
                                    <NavLink to="/">
                                        <img className="logoWhite" src={logo} alt="" />
                                        <img className="logoDark" src={logoDark} alt="" />
                                    </NavLink>
                                </div>
                            </Col>
                            <Col lg={6} className={this.state.responsiveMenu ? 'responsiveMenuWrap active' : 'responsiveMenuWrap'}>
                                <ul className="agencyMainMenu">
                                    {mainMenus.map((menu) => (
                                        <li key={menu}>
                                            {console.log('MENU VALUE', menu)}
                                            <Link
                                                activeClass="active"
                                                spy
                                                smooth
                                                offset={0}
                                                duration={1000}
                                                to={menu}
                                                onClick={
                                                    menu === 'signup' ? this.openDialog :
                                                    menu === 'login' ? this.redirectToWorkFlow :
                                                    menu === 'home' ? this.homeMenu :
                                                    menu === 'discover' ? this.discoverMenu : ''
                                                }
                                            >
                                                {menu}
                                            </Link>
                                        </li>
                                    ))}

                                </ul>
                            </Col>
                            <Col className="d-lg-none d-block" sm={1} xs={4}>
                                <ul
                                    onClick={this.setResponsiveMenu}
                                    className={this.state.responsiveMenu ? 'responsiveMenuTigger active' : 'responsiveMenuTigger'}
                                >
                                    <li className="first"></li>
                                    <li className="second"></li>
                                    <li className="third"></li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Sticky>
                <FormStepperComponent
                    openDialog={openDialog}
                />
            </div >
        )
    }
}

export default ApplicationHeader
